<template>
  <v-row justify="center">
    <v-dialog
      v-model="UserVaultsDetailsDialogState"
      :persistent="true"
      max-width="1080px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Détail des accès</span>
        </v-card-title>
        <v-container>
          <v-card-text>
            <p>
              {{this.$store.state.usersVault.list[this.currentUser].firstName}}
              {{this.$store.state.usersVault.list[this.currentUser].lastName.toUpperCase()}}
              ( {{this.$store.state.usersVault.list[this.currentUser].email}} /
              {{this.$store.state.usersVault.list[this.currentUser].employeeId || '-'}} )
            </p>
            <div class="pa-7" :class="(index %2 === 0)? 'grey lighten-3' : ''" v-for="(item, index) in this.$store.state.usersVault.UserVaultDetails" :key="index">
                <span class="font-weight-bold">Code budget:</span>
                {{item.vault.budgetCode}} - {{item.vault.budgetLabel}}
              <v-row>
                <v-col cols="8" v-if="isOwner(index)">
                  <span> Cet utilisateur est le <span class="font-weight-bold">propriétaire</span> du coffre, aucune action possible</span>
                </v-col>
                <v-col cols="4" v-if="!isOwner(index)">
                  <div class="float-left">
                    <span class="status">{{isOwner(index) ? "Propriétaire" : "Manager" }}</span>
                    <v-switch :disabled="isOwner(index)" v-model="item.profiles" value="ADMIN" class="m-0"></v-switch>
                  </div>
                </v-col>
                <v-col cols="4">
                  <date-picker v-if="!isOwner(index)" :value="item.expirationDate | dateFormat" @input="item.expirationDate = $event" valueType="format" format="DD/MM/YYYY">
                    <template v-slot:input>
                      <v-text-field label="Date de péremption" :value="item.expirationDate | dateFormat" @input="item.expirationDate = $event"></v-text-field>
                    </template>
                  </date-picker>
                </v-col>
                <v-col cols="4">
                  <div class="float-left" v-if="!isOwner(index)">
                    <span class="status">Révoquer l'accès</span>
                    <v-switch color="red" v-model="item.delete" class="m-0"></v-switch>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange"
            class="text-white ma-2"
            elevation="0"
            @click="$emit('closeDialog', false)"
          >
            Annuler
          </v-btn>

          <v-btn
            color="blue darken-1"
            @click="updateUserVault"
            class="text-white ma-2"
            elevation="0"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import config from '../../../config'
import DatePicker from 'vue2-datepicker'

export default {
  name: 'UserVaultsDetails',
  components: { DatePicker },
  data: () => ({
    hideButtons: false,
    successMessage: null,
    errors: [],
    date: null
  }),
  props: {
    currentUser: Number,
    UserVaultsDetailsDialogState: Boolean
  },
  computed: {
    folders () {
      return this.$store.getters.getFolders
    }
  },
  created () {
    // console.log(this.$store.state.usersVault.UserVaultDetails, this.$store.state.usersVault.list[this.currentUser])
  },
  methods: {
    setDate: function (event, item) {
      item.expirationDate = event
    },
    isOwner (index) {
      const current = this.$store.state.usersVault.UserVaultDetails[index]
      return current.profiles.includes('OWNER')
    },
    async updateUserVault () {
      const data = {
        items: []
      }
      for (const item in this.$store.state.usersVault.UserVaultDetails) {
        const current = this.$store.state.usersVault.UserVaultDetails[item]
        current.expirationDate = this.$options.filters.dateFormat(current.expirationDate)
        data.items.push(current)
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/user-vault', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.$emit('closeDialog', true)
            return response
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 500) {
            this.$store.dispatch('messages/addMessage', { text: 'Erreur' })
          }

          this.loading = false
          return e.response
        })
        .then((event) => {
          if (event.data.notify) {
            JSON.parse(event.data.notify).forEach(e => {
              this.$store.dispatch('messages/addMessage', { text: e.text, type: e.type })
            })
          }
        })
    }
  }
}
</script>
