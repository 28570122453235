<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('message.user_invitation') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail*"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="budgetCode"
                  required
                  :rules="[v => !!v || this.$t('message.mandatory_code')]"
                  label="Code budget"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <date-picker :value="expirationFormattedDate " @input="expirationFormattedDate = $event" valueType="format" format="DD/MM/YYYY">
                  <template v-slot:input>
                    <v-text-field :label="$t('message.expiration_date')" :value="expirationFormattedDate " @input="expirationFormattedDate = $event"></v-text-field>
                  </template>
                </date-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  :label="$t('message.user_type')"
                  item-text="text"
                  item-value="value"
                  v-model="profile"
                  required
                  :items="profiles"
                  :rules="[v => !!v || $t('message.type_required')]"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="$emit('closeDialog')" color="danger">
            {{ $t('message.cancel') }}
          </v-btn>

          <v-btn text color="primary" @click="sendDialogForm">{{ $t('message.validate') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import config from '@/config'
import DatePicker from 'vue2-datepicker'

export default {
  name: 'InviteUserToVault',
  components: { DatePicker },
  props: {
    dialogState: {
      type: Boolean
    }
  },
  data: () => ({
    email: null,
    userFilter: '',
    budgetCode: null,
    expirationFormattedDate: null,
    profile: null,

  }),
  computed: {
    emailRules () {
      return [
        v => !!v || this.$t('message.mandatory_email'),
        v => /.+@fiducial.net\s*$/.test(v) || this.$t('message.mandatory_valid_email')
      ]
    },
    profiles () {
      return [
        this.$t('message.administrators'),
        this.$t('message.users')
      ]
    }
  },
  methods: {
    sendDialogForm () {
      const data = {
        userMail: this.email,
        budgetCode: this.budgetCode,
        expirationDate: this.expirationFormattedDate,
        profile: this.profile,
        sectionId: null
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/user-vault', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.email = null
            this.budgetCode = null
            this.expirationFormattedDate = null
            this.profile = null
            this.$emit('closeDialog')
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 500) {
            this.$store.dispatch('messages/addMessage', { text: this.$t('message.error') })
          }

          this.loading = false
          return e.response
        })
        .then((event) => {
          if (event.data.notify) {
            JSON.parse(event.data.notify).forEach(e => {
              this.$store.dispatch('messages/addMessage', { text: e.text, type: e.type })
            })
          }
        })
    }
  }
}
</script>

<style scoped>
.mx-datepicker {
  width: 100%;
}
</style>
