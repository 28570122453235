<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <v-col cols="12" sm="6">
          <h1>Administration des collaborateurs</h1>
        </v-col>

        <v-col cols="12" sm="6" class="justify-end d-flex">
          <v-btn type="button" class="btn teal accent-4 text-white" @click="inviteUserDialogState = true">
          <i class="fa fa-user"></i>
          <span>Inviter un collaborateur</span>
        </v-btn></v-col>
      </header>

      <div class="mb-4">
        <grid
          class="collaborators-table"
          :matrix="$store.state.usersVault.list"
          :formatter="formatter"
          :header="headers"
          :tags.sync="tags"
          :page.sync="page"
          :limit.sync="limit"
          sortable
          @sort="sort"
          @updateSearch="getVaultUsers"
        >
          <template v-slot:action="{ element, index }">
            <td class="actions to-none pa-lg-3" data-label="Actions :">
              <button title="Détails" @click="openDetails(index)"><i class="fas fa-search"></i></button>
            </td>
          </template>

          <template v-slot:budgetCodes="{ text }">
            <td key="budgetCodes">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  v-if="text.length < 30"
                  class="font-weight-bold"
                >
                  {{ text }}
                </span>
                <span
                  v-else
                  v-bind="attrs"
                  v-on="on"
                  class="font-weight-bold">
                  {{ text.substring(0,30)+" (voir plus)" }}
                </span>
              </template>
              <span>{{ text }}</span>
            </v-tooltip>
            </td>
          </template>

          <template v-slot:budgetLabels="{ text }">
            <td v-if="text" key="budgetLabels">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  v-if="text.length < 30"
                  class="font-weight-bold"
                >
                  {{ text }}
                </span>
                  <span
                    v-else
                    v-bind="attrs"
                    v-on="on"
                    class="font-weight-bold">
                  {{ text.substring(0,30)+" (voir plus)" }}
                </span>
                </template>
                <span>{{ text }}</span>
              </v-tooltip>
            </td>
            <td v-else key="budgetLabels">
              -
            </td>
          </template>

          <template v-slot:[slotName]="{ text }" v-for="slotName in ['lastName', 'firstName']">
            <td :key="slotName"><span class="font-weight-bold">{{ text ? text : '-' }}</span></td>
          </template>
        </grid>
      </div>
    </div>

    <UserVaultsDetailsDialog
      v-if="UserVaultsDetailsDialogState"
      :UserVaultsDetailsDialogState="UserVaultsDetailsDialogState"
      :key="Date.now()"
      :currentUser="currentUserIndex"
      @closeDialog="closeDetailsDialog"
    ></UserVaultsDetailsDialog>

    <invite-user-to-vault
      :dialog-state="inviteUserDialogState"
      @closeDialog="() => { inviteUserDialogState = false; getVaultUsers() }"
    >
    </invite-user-to-vault>

  </div>
</template>

<script>
import Vue from 'vue'
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'
import Grid from '@/components/Grid'
import UserVaultsDetailsDialog from '@/components/Dialog/Users/UserVaultsDetailsDialog'
import InviteUserToVault from '@/components/Dialog/Users/InviteUserToVault'

export default {
  name: 'Collaborators',
  components: { Grid, UserVaultsDetailsDialog, InviteUserToVault },
  data: () => ({
    alertSuccess: false,
    alertError: false,
    successMessage: null,
    UserVaultsDetailsDialogState: false,
    inviteUserDialogState: false,
    loading: false,
    showBudgetCodes: true,
    date: null,
    currentUserIndex: null,
    sortBy: '',
    sortType: '',
    limit: 'tout',
    page: 1,
    tags: [],
    formatter: {
      createdAt: 'date',
      lastLoginAt: 'date'
    },
    headers: {
      budgetCodes: 'Code budgets',
      budgetLabels: 'Label budgets',
      lastName: 'Nom',
      firstName: 'Prénom',
      employeeId: 'Matricule',
      email: 'Email',
      createdAt: 'Date de création',
      lastLoginAt: 'Dernière connexion',
      action: 'Actions'
    }
  }),
  created() {
    this.$emit('update:layout', DefaultLayout)

    this.getVaultUsers()

    document.title = config.title + ' - Gestion des collaborateurs'
  },
  methods: {
    closeDetailsDialog: function (e) {
      this.UserVaultsDetailsDialogState = false
      if (e) {
        this.getVaultUsers()
      }
    },
    sort: function (e) {
      this.sortType = (this.sortBy === e && this.sortType === 'ASC') ? 'DESC' : 'ASC'
      this.sortBy = e
      this.getVaultUsers()
    },
    openDetails: async function (e) {
      this.loading = true
      this.currentUserIndex = e
      await this.$store.dispatch('usersVault/getUser', this.$store.state.usersVault.list[e].id).then(() => {
        this.UserVaultsDetailsDialogState = true
        this.loading = false
      })
    },
    getVaultUsers: async function () {
      this.loading = true

      const params = {
        sortType: this.sortType !== '' ? this.sortType : 'ASC',
        sortBy: this.sortBy !== '' ? this.sortBy : 'u.id',
        page: this.page
      }

      if (this.limit !== 'tout') {
        params.limit = this.limit
      }

      const terms = []
      for (const element of this.tags) {
        terms.push(element)
      }
      if (terms.length) {
        params.terms = terms.join(',')
      }

      this.loading = await this.$store.dispatch('usersVault/getData', params)
    },
    putVault (user) {
      const data = {
        subscribed: user.subscribed
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/vaults/' + user.vaultId, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const vault = response.data

            if (vault) {
              this.alertSuccess = true
              this.successMessage = 'La souscription a été mise à jour.'
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response && (response.status === 400 || response.status === 404)) {
            this.alertError = true
            this.successMessage = null
            this.alertSuccess = false
          }

          this.loading = false
        })
    }
  }
}
</script>

<style>
  .icon-size {
    font-size: 1.5em !important;
  }
  .mdl-table-custom.sm-2 tr td, .mdl-table-custom.sm-2 tr th{
    max-width: 30vw !important;
  }
</style>
