var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"content"}},[(_vm.loading)?_c('div',{attrs:{"id":"loading"}},[_c('div',{staticClass:"dot-flashing"})]):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('header',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Administration des collaborateurs")])]),_c('v-col',{staticClass:"justify-end d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{staticClass:"btn teal accent-4 text-white",attrs:{"type":"button"},on:{"click":function($event){_vm.inviteUserDialogState = true}}},[_c('i',{staticClass:"fa fa-user"}),_c('span',[_vm._v("Inviter un collaborateur")])])],1)],1),_c('div',{staticClass:"mb-4"},[_c('grid',{staticClass:"collaborators-table",attrs:{"matrix":_vm.$store.state.usersVault.list,"formatter":_vm.formatter,"header":_vm.headers,"tags":_vm.tags,"page":_vm.page,"limit":_vm.limit,"sortable":""},on:{"update:tags":function($event){_vm.tags=$event},"update:page":function($event){_vm.page=$event},"update:limit":function($event){_vm.limit=$event},"sort":_vm.sort,"updateSearch":_vm.getVaultUsers},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var element = ref.element;
var index = ref.index;
return [_c('td',{staticClass:"actions to-none pa-lg-3",attrs:{"data-label":"Actions :"}},[_c('button',{attrs:{"title":"Détails"},on:{"click":function($event){return _vm.openDetails(index)}}},[_c('i',{staticClass:"fas fa-search"})])])]}},{key:"budgetCodes",fn:function(ref){
var text = ref.text;
return [_c('td',{key:"budgetCodes"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(text.length < 30)?_c('span',_vm._g(_vm._b({staticClass:"font-weight-bold"},'span',attrs,false),on),[_vm._v(" "+_vm._s(text)+" ")]):_c('span',_vm._g(_vm._b({staticClass:"font-weight-bold"},'span',attrs,false),on),[_vm._v(" "+_vm._s(text.substring(0,30)+" (voir plus)")+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(text))])])],1)]}},{key:"budgetLabels",fn:function(ref){
var text = ref.text;
return [(text)?_c('td',{key:"budgetLabels"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(text.length < 30)?_c('span',_vm._g(_vm._b({staticClass:"font-weight-bold"},'span',attrs,false),on),[_vm._v(" "+_vm._s(text)+" ")]):_c('span',_vm._g(_vm._b({staticClass:"font-weight-bold"},'span',attrs,false),on),[_vm._v(" "+_vm._s(text.substring(0,30)+" (voir plus)")+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(text))])])],1):_c('td',{key:"budgetLabels"},[_vm._v(" - ")])]}},_vm._l((['lastName', 'firstName']),function(slotName){return {key:slotName,fn:function(ref){
var text = ref.text;
return [_c('td',{key:slotName},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(text ? text : '-'))])])]}}})],null,true)})],1)]),(_vm.UserVaultsDetailsDialogState)?_c('UserVaultsDetailsDialog',{key:Date.now(),attrs:{"UserVaultsDetailsDialogState":_vm.UserVaultsDetailsDialogState,"currentUser":_vm.currentUserIndex},on:{"closeDialog":_vm.closeDetailsDialog}}):_vm._e(),_c('invite-user-to-vault',{attrs:{"dialog-state":_vm.inviteUserDialogState},on:{"closeDialog":function () { _vm.inviteUserDialogState = false; _vm.getVaultUsers() }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }